import React from 'react'
import { CFSelect, CFView, LocationSelect, MobileScreen } from 'components'
import Header from './Header'
import Hero from './Hero'
import Promotions from './Promotions'
import About from './About'
import Gallery from './Gallery'
import Contact from './Contact'
import Footer from './Footer'
import WelcomeModal from './WelcomeModal'
import { dispatch } from 'store'
// import { homebg } from 'images'

export default () => {
  return (
    <CFView row justifyCenter>
      <CFView maxWidth="1400px" w="100%" bg="white">
        {/* <MobileScreen>
          <LocationSelect />
        </MobileScreen> */}
        {/* <Header /> */}
        <Hero />
        <Promotions />
        {/* <About /> */}
        {/* <Gallery /> */}
        <Contact />
        <Footer />
      </CFView>
      <CFSelect selector={dispatch.restaurant.getWelcomeMessage}>
        <WelcomeModal />
      </CFSelect>
    </CFView>
  )
}
