import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
  mobileLogo,
} from 'images'
import ViewMenu from './ViewMenu'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          maxHeight="850px"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          justifyBetween
          alignCenter
        >
          <CFView w="100%" column center pv="10px">
            <CFImage src={mobileLogo} w="75%" maxWidth="350px" alt="About" />
          </CFView>
          <CFView bg="rgba(0,0,0,.8)" w="100%">
            <CFView pulsate textCenter pv="10px" w="100%">
              <OrderPickupButton />
            </CFView>
            <CFView textCenter w="100%">
              <ViewMenu />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="rgba(0,0,0,.8)" ph="50px" pv="1px">
            <CFImage
              src={heroText}
              w="100%"
              mt="5px"
              maxWidth="400px"
              alt="About"
            />
            <CFView row center mt="5px">
              <CFView ml="20px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView ml="20px">
                <ViewMenu />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
